/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, Suspense } from "react";
import { Layout, Space, Avatar, Popover,FloatButton  } from "antd";
import { LoginOutlined } from "@ant-design/icons";
import { Route, Routes, useNavigate } from "react-router-dom";
import { useDispatch,useSelector } from "react-redux";
import Spinner from "../common/spinner";
import NavBar from "./Navbar";
import { setUserData,setActiveNavItem } from "../../utils/redux/actions";
import FloatIcon from "../../images/floatIcon.svg";
import passwordIcon from "../../images/password-icon.png";
import profileIcon from "../../images/profile-icon.png";
import faIcon from "../../images/2faIcon.png";
import kycIcon from "../../images/kycicon.png";
import qrcodeIcon from "../../images/QRicon.png";
import logoutAllIcon from "../../images/logouticon.png";
import logoutIcon from "../../images/Logoutarrow.png";
import "./styles.scss";

const Dashboard = React.lazy(() => import("../Dashboard"));
const MoneyIn = React.lazy(() => import("../MoneyIn"));
const VoucherList = React.lazy(() => import("../Voucher/VoucherList"));
const RedeemVoucherList = React.lazy(() => import("../Voucher/RedeemVoucher"));
const AddNewVoucher = React.lazy(() => import("../Voucher/AddnewVoucher"));
const TransactionHistory = React.lazy(() =>
  import("../Transactions/TransactionHistory")
);
const AddMoney = React.lazy(() => import("../AddMoney"));
const AddMoneyHistory = React.lazy(() => import("../AddMoneyHistory"));
const WithDrawMoney = React.lazy(() => import("../WithdrawMoney"));
const AddWithDrawMethod = React.lazy(() => import("../WithdrawMoney/AddWithdrawMethod"));
const WithDrawHistory = React.lazy(() => import("../WithdrawHistory"));
const TransferMoney = React.lazy(() => import("../TransferMoney"));
const RequestMoney = React.lazy(() => import("../RequestMoney"));
const MyRequestHistory = React.lazy(() => import("../RequestMoney/MyRequestHistory"));
const MoneyRequests = React.lazy(() => import("../RequestMoney/MoneyRequests"));
const VoucherAll = React.lazy(() => import("../Voucher"));
const UserProfile = React.lazy(() => import("../UserProfile"));
const ChangePassword = React.lazy(() => import("../ChangePassword"));
const SupportTicket = React.lazy(() => import("../SupportTicket"));
const AddnewTicket = React.lazy(() => import("../SupportTicket/AddnewTicket"));
const TicketDetail = React.lazy(() => import("../SupportTicket/TicketDetail"));
// ExchangeMoney
const ExchangeMoney = React.lazy(() => import("../ExchangeMoney"));
// Invoices
const InvoicesAll = React.lazy(() => import("../Invoices"));
const AddNewInvoice = React.lazy(() => import("../Invoices/CreateInvoice"));
const UpdateInvoice = React.lazy(() => import("../Invoices/UpdateInvoice"));
// kyc
const KycSection = React.lazy(() => import("../KycSection"));
// 2fa auth
const TwoFactorsAuthentication = React.lazy(() => import("../TwoFactorAuthentication"));
// Qr code
const MyQRCode = React.lazy(() => import("../MyQrCode"));
// logout from all devices
const LogoutDevices = React.lazy(() => import("../LogoutDevices"));
// screen lock
const ScreenLock = React.lazy(() => import("../ScreenLock"));
// 
const { Header, Content } = Layout;

const AdminLayout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [collapsed, setCollapsed] = useState(false);
  const [open, setOpen] = useState(false);

  const { profileData } = useSelector((state) => {
    return state?.app;
  })

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  const handleChangeLogout = () => {
    navigate("/admin/change-password");
    setOpen(false);
  };

  const handle2Fa = (item) => {
    navigate(item);
    setOpen(false);
  };

  const userPopover = () => {
    return (
      <section className="user-inner-container">
        <div className="profile-opt" onClick={handleProfileClick}>
        <img src={profileIcon} alt="#"/>
          Profile
        </div>
        <div className="password-opt" onClick={()=>handle2Fa("/change-password")}>
        <img src={passwordIcon} alt="#"/>
          Change Password
        </div>
        <div className="password-opt" onClick={()=>handle2Fa("/kyc")}>
        <img src={kycIcon} alt="#"/>
          KYC
        </div>
        <div className="password-opt" onClick={()=>handle2Fa("/2fa-authentication")}>
        <img src={faIcon} alt="#"/>
          2FA Security
        </div>
        <div className="password-opt" onClick={()=>handle2Fa("/qr-code")}>
        <img src={qrcodeIcon} alt="#"/>
          My QR Code
        </div>
        <div className="password-opt" onClick={()=>handle2Fa("/logout-from-alldevices")}>
        <img src={logoutAllIcon} alt="#"/>
          Logout From Others
        </div>        
        <div className="logout-opt" onClick={handleLogout}>
        <img src={logoutIcon} alt="#"/>
          Logout
        </div>
      </section>
    );
  };

  const openMenu = () => {
    setOpen(!open);
  };

  const handleProfileClick = () => {
    navigate(`/user/profile`);
    dispatch(setActiveNavItem("/profile"))
    setOpen(false);
  };

  const handleLogout = () => {
    localStorage.removeItem("wallet-user-panel-data");
    dispatch(setUserData({}));
    navigate("/");
    setOpen(false);
  };

  const handleNavigate=()=>{
    navigate("/support-ticket")
    dispatch(setActiveNavItem("support-ticket"))
  }

  return (
    <>
      <Header className="site-layout-background bugify-navbar">
        <div className="merchant-name">{profileData?.first_name} {profileData?.last_name}</div>
        <Space align="center" size={20}>
          <div className="support-ticket-text" onClick={()=>handleNavigate()}> Support Ticket &nbsp;&nbsp; | &nbsp;&nbsp;</div>
          <Popover
            placement="bottomLeft"
            content={userPopover()}
            trigger="click"
            className="user-popover"
            open={open}            
          >
           
            <Avatar onClick={openMenu} src={profileData?.image} /> &nbsp;
            <span onClick={openMenu} className="admin-text">{profileData?.first_name} {profileData?.last_name}</span>
          </Popover>
        </Space>
      </Header>
      <NavBar/>
      <FloatButton icon={<img src={FloatIcon} alt="#"/>} onClick={() => console.log('onClick')} />
      <Layout>
        <Layout className="site-layout">
          <Content className="site-layout-background content-main-section">
            <Suspense fallback={<Spinner />}>
              <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route path="/money-in" element={<MoneyIn />} />
                <Route
                  path="/transaction-history"
                  element={<TransactionHistory />}
                />
                <Route path="/voucher-list" element={<VoucherList />} />
                <Route path="/redeem-voucher" element={<RedeemVoucherList />} />
                <Route path="/create-voucher" element={<AddNewVoucher />} />
                <Route path="/voucher" element={<VoucherAll />} />
                <Route path="/add-money" element={<AddMoney />} />
                <Route path="/add-money-history" element={<AddMoneyHistory />} />
                <Route path="/transfer-money" element={<TransferMoney />} />
                <Route path="/request-money" element={<RequestMoney />} />
                <Route path="/request-history" element={<MyRequestHistory />} />
                <Route path="/my-requests-money" element={<MoneyRequests />} />            
                <Route path="/withdraw-money" element={<WithDrawMoney />} />
                <Route path="/add-withdraw-method" element={<AddWithDrawMethod />} />
                <Route path="/withdraw-history" element={<WithDrawHistory />} />
                <Route path="/user/profile" element={<UserProfile />} />
                <Route path="/change-password" element={<ChangePassword />} />             
                <Route path="/support-ticket" element={<SupportTicket />} />
                <Route path="/add-support-ticket" element={<AddnewTicket />} />
                <Route path="/ticket-detail/:ticketId" element={<TicketDetail />} />
                {/* Exchange Money */}
                <Route path="/exchange-money" element={<ExchangeMoney />} />
                {/* Invoices */}
                <Route path="/all-invoices" element={<InvoicesAll />} />
                <Route path="/add-new-invoice" element={<AddNewInvoice />} />
                <Route path="/update-invoice/:invoiceId" element={<UpdateInvoice />} />
                {/* kyc */}
                <Route path="/kyc" element={<KycSection />} />
                {/* 2fa */}
                <Route path="/2fa-authentication" element={<TwoFactorsAuthentication />} />
                {/* qrcode */}
                <Route path="/qr-code" element={<MyQRCode />} />
                {/* logout */}
                <Route path="/logout-from-alldevices" element={<LogoutDevices />} />
                {/* screen lock */}
                <Route path="/logout-from-alldevices" element={<ScreenLock />} />
                {/*  */}
              </Routes>
            </Suspense>
          </Content>
        </Layout>
      </Layout>
    </>
  );
};

export default AdminLayout;
