import { React, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch,useSelector } from "react-redux";
import { setActiveNavItem } from "../../../utils/redux/actions";
import LogoImg from "../../../images/image 1.svg";
import RequestMoneyMenu from "./requestMoneyMenu";
import VoucherMenu from "./voucherMenu";
import WithdrawMenu from "./withDrawsmenu";
import AddMoneyMenu from "./addmoneyMenu";
import "./styles.scss";

const NavBar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  

  const handleNavItemClick = (navItem) => {
    dispatch(setActiveNavItem(navItem));
    navigate(`/${navItem}`);
  };

  const { activeNavItem } = useSelector((state) => {   
    return state?.app;
  });

  return (
    <section className="navbar-container">
      <section className="left-section">
        <div className="fastcash-logo"  onClick={() => handleNavItemClick("")} >
          <img className="collapsed-img" src={LogoImg} alt="" />
        </div>
      </section>
      <section className="right-section">
        <div
          style={{
            color: activeNavItem === "" ? "#229ABD" : "#000",
          }}
          className="navbar-item"
          onClick={() => handleNavItemClick("")}
        >
          Dashboard
        </div>
        <div
          style={{
            color: activeNavItem === "add-money" ? "#229ABD" : "#000",
          }}
          onClick={() => dispatch(setActiveNavItem("add-money"))}
          className="navbar-item"
        >
          <AddMoneyMenu />
        </div>
        <div
          style={{
            color: activeNavItem === "money-in" ? "#229ABD" : "#000",
          }}
          className="navbar-item"
          onClick={() => handleNavItemClick("money-in")}
        >
          Money Discharge
        </div>

        <div
          style={{
            color: activeNavItem === "transfer-money" ? "#229ABD" : "#000",
          }}
          className="navbar-item"
          onClick={() => handleNavItemClick("transfer-money")}
        >
          Transfer
        </div>
        <div
          style={{
            color: activeNavItem === "request-money" ? "#229ABD" : "#000",
          }}
          onClick={() => dispatch(setActiveNavItem("request-money"))}
          className="navbar-item"
        >
          <RequestMoneyMenu />
        </div>

        <div
          style={{
            color: activeNavItem === "vouchers" ? "#229ABD" : "#000",
          }}
          onClick={() => dispatch(setActiveNavItem("vouchers"))}
          className="navbar-item"
        >
          <VoucherMenu />
        </div>
        <div
          style={{
            color: activeNavItem === "withdraws" ? "#229ABD" : "#000",
          }}
          onClick={() => dispatch(setActiveNavItem("withdraws"))}
          className="navbar-item"
        >
          <WithdrawMenu />
        </div>
        <div
          style={{
            color: activeNavItem === "transaction-history" ? "#229ABD" : "#000",
          }}
          className="navbar-item"
          onClick={() => handleNavItemClick("transaction-history")}
        >
          Transactions
        </div>
      </section>
    </section>
  );
};

export default NavBar;
