import Auth from "./Auth";
import Support from "./Support";
import Withdraws from "./Withdraws";
import Invoice from "./Invoice";
import Vouchers from "./Vouchers";
import TransferMoney from "./TransferMoney";
import Dashboard from "./Dashboard";
import Payments from "./Payments";
import FixedPage from "./FixedPage";
import Profile from "./Profile";
import Currency from "./Currency";
import Kyc from "./Kyc";
import QRCode from "./QRcode";
const API = {
  
     
  
  FixedPage, 
  
  //
  Dashboard,
  TransferMoney,
  Vouchers,
  Withdraws, 
  Payments,
  Auth,
  Support,
  Profile,
  Invoice, 
  Currency,
  Kyc,
  QRCode
};

export default API;
