import { createAction } from "redux-actions";

export const setUserData = createAction(
  "SET_USER_DATA",
  (userData) => userData
);
export const setUserWallets = createAction(
  "SET_USER_WALLETS",
  (userWallets) => userWallets
);
export const setProfileData = createAction(
  "SET_PROFILE_DATA",
  (profileData) => profileData
);

export const setActiveNavItem = createAction(
  "SET_ACTIVE_NAVITEM",
  (activeNavItem) => activeNavItem
);
export const setShowLockScreen = createAction(
  "SET_SHOW_LOCK",
  (showLockScreen) => showLockScreen
);