/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect,useState } from "react";
import { message } from "antd";
import { Route, Routes, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AdminLayout from "./layout";
import { setUserData } from "../utils/redux/actions";
import { setProfileData } from "../utils/redux/actions";
import { setShowLockScreen } from "../utils/redux/actions";
import API from "../utils/api";
import { getDataManager, getErrorMessage } from "../utils/helper.functions";
import Login from "./auth/log-in";
import SignIn from "./auth/sign-in";
import EmailVerificationModal from "./auth/email-verification";
import ResetPassword from "./auth/reset-password";
import "./fonts.scss";
import "./App.scss";
import ScreenLock from "./ScreenLock";

const App = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const profile = new API.Profile();
  const lock = new API.Auth();

  const { userData } = useSelector((state) => {
    return state?.app;
  });
  const { showLockScreen } = useSelector((state) => {
    return state?.app;
  });

  const savedUserData = JSON.parse(
    localStorage.getItem("wallet-user-panel-data")
  );

  const [lockPermission, setLockPermission] = useState(false);

  useEffect(() => {
    dispatch(setUserData(savedUserData));
    fetchProfileData();
    fetchLockScreen();
  }, []);

  useEffect(() => {
    if (lockPermission === true) {
      let inactivityTimer;
      const resetTimer = () => {
        clearTimeout(inactivityTimer);
        inactivityTimer = setTimeout(() => {
          dispatch(setShowLockScreen(true));
          localStorage.removeItem("wallet-user-panel-data");
          dispatch(setUserData({}));
        }, 5 * 60 * 1000); // 5 minutes in milliseconds
      };

      // Set up event listeners
      document.addEventListener("mousemove", resetTimer);
      document.addEventListener("keydown", resetTimer);

      //   // Initial timer setup
      resetTimer();

      //   // Clean up event listeners on component unmount
      return () => {
        document.removeEventListener("mousemove", resetTimer);
        document.removeEventListener("keydown", resetTimer);
        clearTimeout(inactivityTimer);
      };
    }
  }, [lockPermission]);

  const fetchProfileData = async () => {
    return getDataManager(profile?.getProfileData).then((x) => {
      if (x?.status === true) {
        dispatch(setProfileData(x?.data));
      } else {
        // const error = getErrorMessage(x?.errors) || x?.message;
        // message.error({
        //   content: error || "Error ocurred",
        //   duration: 3,
        // });
      }
    });
  };
  const fetchLockScreen = async () => {
    return getDataManager(lock?.lockScreen).then((x) => {
      if (x?.status === true) {
        setLockPermission(x?.data?.permission);
      } else {
        // const error = getErrorMessage(x?.errors) || x?.message;
        // message.error({
        //   content: error || "Error ocurred",
        //   duration: 3,
        // });
      }
    });
  };
  const isLoggedIn = !!savedUserData?.token || !!userData?.token;

  useEffect(() => {
    if (!isLoggedIn && !showLockScreen) {
      navigate("/login");
    }
  }, [isLoggedIn]);

  return (
    <div className="user-main-container">
      {showLockScreen ? (
        <ScreenLock />
      ) : isLoggedIn ? (
        <AdminLayout />
      ) : (
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/sign-in" element={<SignIn />} />
          <Route
            path="/email-verification/:email"
            element={<EmailVerificationModal />}
          />
          <Route path="/reset-password" element={<ResetPassword />} />
        </Routes>
      )}
    </div>
  );
};

export default App;
