import axios from "axios";
import { API_URL, ACCESS_TOKEN } from "../constants";

export default class Invoice {
  getAllInvoices = (payload = {}) => {
    return axios
      .get(`${API_URL}api/v1/users/invoices`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${ACCESS_TOKEN}`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };
  getInvoiceDetails = (id) => {
    return axios
      .get(`${API_URL}api/v1/users/invoices/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${ACCESS_TOKEN}`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };
  addInvoice = (formData) => {
    const url = `${API_URL}api/v1/users/invoices`;
    return axios({
      url,
      method: "POST",
      data: formData,
      headers: {
        Authorization: `Token ${ACCESS_TOKEN}`,
      },
    })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error.response?.data;
      });
  };
  updateInvoice = (formData,id) => {
    const url = `${API_URL}api/v1/users/invoices/${id}`;
    return axios({
      url,
      method: "PATCH",
      data: formData,
      headers: {
        Authorization: `Token ${ACCESS_TOKEN}`,
      },
    })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error.response?.data;
      });
  };
  publishInvoice = (id) => {
    const url = `${API_URL}api/v1/users/invoices/${id}/publish`;
    return axios({
      url,
      method: "PATCH",      
      headers: {
        Authorization: `Token ${ACCESS_TOKEN}`,
      },
    })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error.response?.data;
      });
  };
  discardInvoice = (id) => {
    const url = `${API_URL}api/v1/users/invoices/${id}/discard`;
    return axios({
      url,
      method: "PATCH",      
      headers: {
        Authorization: `Token ${ACCESS_TOKEN}`,
      },
    })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error.response?.data;
      });
  };
  mailInvoice = (id) => {
    const url = `${API_URL}api/v1/users/invoices/${id}/send-mail`;
    return axios({
      url,
      method: "POST",      
      headers: {
        Authorization: `Token ${ACCESS_TOKEN}`,
      },
    })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error.response?.data;
      });
  };
  // Exchange Money
  exchangeMoney = (formData) => {
    const url = `${API_URL}api/v1/users/money-exchange`;
    return axios({
      url,
      method: "POST",
      data: formData,
      headers: {
        Authorization: `Token ${ACCESS_TOKEN}`,
      },
    })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error.response?.data;
      });
  };
}
