import React from "react";
import { useNavigate } from "react-router-dom";
import { Dropdown, Space } from "antd";
import ArrowLineDownImg from "../../../../images/ArrowLineDown-s.svg";
import "./styles.scss"


const WithdrawMenu = () => {
  const navigate = useNavigate();

  const items = [
    {
      key: "1",
      label: (
        <div className="drop-down-item" onClick={()=>navigate("/withdraw-money")}>
         Withdraw Money
        </div>
      ),
    },
    {
      type: 'divider',
    },
    {
      key: "2",
      label: (
        <div className="drop-down-item" onClick={()=>navigate("/add-withdraw-method")}>
          Add Withdraw Method
        </div>
      ),
    },
    {
      type: 'divider',
    },
    {
      key: "3",
      label: (
        <div className="drop-down-item" onClick={()=>navigate("/withdraw-history")}>
         Withdraw Log
        </div>
      ),
    
      
    },
  ];
  
  return (
    <Dropdown
      menu={{
        items,
      }}
      className="drop-down-menu"
    >
      <Space direction="horizontal" size={5}>
        Withdraws
        <img src={ArrowLineDownImg} alt="#" className="navbar-arrow-img" />
      </Space>
    </Dropdown>
  );
};

export default WithdrawMenu;
