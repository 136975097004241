import React from "react";
import { useNavigate } from "react-router-dom";
import { Dropdown, Space } from "antd";
import ArrowLineDownImg from "../../../../images/ArrowLineDown-s.svg";

const RequestMoneyMenu = () => {
  const navigate = useNavigate();

  const items = [
    {
      key: "1",
      label: (
        <div
          className="drop-down-item"
          onClick={() => navigate("/request-money")}
        >
          Request Money
        </div>
      ),
    },
    {
      type: "divider",
    },
    {
      key: "2",
      label: (
        <div
          className="drop-down-item"
          onClick={() => navigate("/my-requests-money")}
        >
          Money Requests To Me
        </div>
      ),
    },
    {
      type: "divider",
    },
    {
      key: "3",
      label: (
        <div
          className="drop-down-item"
          onClick={() => navigate("/request-history")}
        >
          My Request History
        </div>
      ),
    },
  ];
  return (
    <Dropdown
      menu={{
        items,
      }}
    >
      <Space direction="horizontal" size={5}>
        Request Money
        <img src={ArrowLineDownImg} alt="#" className="navbar-arrow-img" />
      </Space>
    </Dropdown>
  );
};

export default RequestMoneyMenu;
