/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Form, Input, Button, message, Row, Col, Card, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import OtpInput from "react-otp-input";
import Spinner from "../../common/spinner";
import API from "../../../utils/api";
import {
  getDataManager,
  getErrorMessage,
} from "../../../utils/helper.functions";
import authImg from "../../../images/loginImg.svg";
import "./styles.scss";

const { Title, Paragraph } = Typography;

const ResetPassword = () => {
  const [form] = Form.useForm();

  const auth = new API.Auth();

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [showOtp, setShowOtp] = useState(false);
  const [otp, setOtp] = useState();
  const [username, setUsername] = useState("");
  const [verificationCode, setVerificationCode] = useState();

  const handleOtpChange = (o) => {
    setVerificationCode(o);
  };

  const requestForgetPassword = (payload) => {
    getDataManager(auth?.forgetPassword, setLoading, payload).then((x) => {
      if (x?.status === true) {
        message.success({
          content: "A code has been sent to your Email",
          duration: 3,
        });
        setShowOtp(true);
      } else {
        const error = getErrorMessage(x?.errors);
        message.error({
          content: x?.message || error || "Error ocurred while sending otp",
          duration: 3,
        });
        setShowOtp(true);
      }
    });
  };

  const createNewPassword = (values) => {
    if (otp.length === 6) {
      const payload = {
        code: otp,
        email:username,
        new_password: values?.password,        
      };
      getDataManager(auth?.forgetPasswordConfirm, setLoading, payload).then(
        (x) => {
          if (x?.status === true) {
            message.success({
              content: "Password has been updated successfully",
              duration: 3,
            });
            navigate("/login");
          } else {
            const error = getErrorMessage(x?.errors);
            message.error({
              content: x?.message || error || "Error ocurred while sending otp",
              duration: 3,
            });
          }
        }
      );
    } else {
      message.error({ content: "Please enter the otp!", duration: 3 });
    }
  };

  return (
    <Row>
      <Col span={12} className="reset-right-section">
        <img src={authImg} alt="" />
      </Col>
      <Col span={12} className="reset-left-section">
        {!showOtp ? (
          <Card className="login-card" bordered={false}>
            {loading && <Spinner />}

            <Title level={4} className="login-title">
              Forgotten Password ?
            </Title>
            <Paragraph className="forget-thankyou-text">
              We will send you an email allow you to reset your password.
            </Paragraph>
            <Form
              form={form}
              onFinish={requestForgetPassword}
              layout="vertical"
              scrollToFirstError
            >
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please enter email",
                  },
                ]}
              >
                <Input
                  placeholder="email"
                  size="large"
                  className="form-input"
                  onChange={(e) => setUsername(e.target.value)}
                />
              </Form.Item>
              <Form.Item>
                <Button htmlType="submit" size="large" type="danger" className="forget-submit-button">
                  Send Verification Mail
                </Button>
              </Form.Item>
            </Form>
            <div className="dont-have-account">
              Don't have an account?{" "}
              <span
                onClick={() => navigate("/sign-in")}
                className="sin-up-text"
              >
                Sign Up
              </span>
            </div>
          </Card>
        ) : (
          <Card className="login-card" bordered={false}>
            {loading && <Spinner />}
            <Title level={4} className="login-title">
            OTP Verification
            </Title>
            <div className="otp-code-text">
            We have sent a one time password to your email address
            </div>
            <div className="otp-code-text-label">
            Please enter the otp code to verify your account.
            </div>
            <Form
              form={form}
              layout="vertical"
              onFinish={createNewPassword}
              scrollToFirstError
            >
              <Form.Item>
                <OtpInput
                  numInputs={6}
                  value={otp}
                  onChange={(e) => setOtp(e)}
                  containerStyle="verification-code-container"
                  inputStyle="one-digit-input"
                />
              </Form.Item>
              <Form.Item
                label="New Password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please enter new password",
                  },
                ]}
              >
                <Input
                  placeholder="New password"
                  size="large"
                  className="form-input"
                />
              </Form.Item>
              <div className="didnot-receive" onClick={()=>setShowOtp(false)}>
              I didn’t receive any code, RESEND
              </div>
              
              <Form.Item>
                <Button htmlType="submit" size="large" type="danger" className="forget-submit-button">
                  Password Reset
                </Button>
              </Form.Item>
            </Form>
          </Card>
        )}
      </Col>
    </Row>
  );
};

export default ResetPassword;
