import React from "react";
import { useNavigate } from "react-router-dom";
import { Dropdown, Space } from "antd";
import ArrowLineDownImg from "../../../../images/ArrowLineDown-s.svg";

const AddMoneyMenu = () => {
  const navigate = useNavigate();

  const items = [
    {
      key: "1",
      label: <div className="drop-down-item" onClick={() => navigate("/add-money")}>Add Money</div>,
    },
    {
      type: 'divider',
    },
    {
      key: "2",
      label: <div className="drop-down-item" onClick={() => navigate("/add-money-history")}>Add Money History</div>,
    },
  ];

  return (
    <Dropdown
      menu={{
        items,
      }}
    >
      <Space direction="horizontal" size={5}>
       Add Money
        <img src={ArrowLineDownImg} alt="#" className="navbar-arrow-img"/>
      </Space>
    </Dropdown>
  );
};

export default AddMoneyMenu;
