import React from "react";
import { useNavigate } from "react-router-dom";
import { Dropdown, Space } from "antd";
import ArrowLineDownImg from "../../../../images/ArrowLineDown-s.svg";

const VoucherMenu = () => {
  const navigate = useNavigate();

  const items = [
    {
      key: "1",
      label: <div className="drop-down-item" onClick={() => navigate("/voucher-list")}>My Vouchers</div>,
    },
    {
      type: 'divider',
    },
    {
      key: "2",
      label: <div className="drop-down-item" onClick={() => navigate("/voucher")}>Vouchers Redeem</div>,
    },
  ];

  return (
    <Dropdown
      menu={{
        items,
      }}
    >
      <Space direction="horizontal" size={5}>
        Voucher
        <img src={ArrowLineDownImg} alt="#" className="navbar-arrow-img" />
      </Space>
    </Dropdown>
  );
};

export default VoucherMenu;
