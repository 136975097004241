import axios from "axios";
import { API_URL, ACCESS_TOKEN } from "../constants";

export default class Dashboard {
  getUserWallets = () => {
    const url = `${API_URL}api/v1/users/wallets`;
    return axios
      .get(url, {
        headers: {
          Authorization: `Token ${ACCESS_TOKEN}`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error.response?.data;
      });
  };
  getUserBalance = () => {
    const url = `${API_URL}api/v1/users/wallets/total-balance`;
    return axios
      .get(url, {
        headers: {
          Authorization: `Token ${ACCESS_TOKEN}`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error.response?.data;
      });
  };
  getLatestTransactions = () => {
    const url = `${API_URL}api/v1/users/transactions/latest`;
    return axios
      .get(url, {
        headers: {
          Authorization: `Token ${ACCESS_TOKEN}`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error.response?.data;
      });
  };
  getUserMoneyIn = (days) => {
    const url = `${API_URL}api/v1/users/transactions/money-in-insight?days=${days}`;
    return axios
      .get(url, {
        headers: {
          Authorization: `Token ${ACCESS_TOKEN}`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error.response?.data;
      });
  };
  getUserMoneyOut = (days) => {
    const url = `${API_URL}api/v1/users/transactions/money-out-insight?days=${days}`;
    return axios
      .get(url, {
        headers: {
          Authorization: `Token ${ACCESS_TOKEN}`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error.response?.data;
      });
  };
  getAllTransactions = (payload = {}) => {
    return axios
      .get(
        `${API_URL}api/v1/users/transactions?trxType=${
          payload?.trxType || ""
        }&operation_type=${payload?.operationType || ""}&history_from=${
          payload?.historyFrom || ""
        }&currency=${payload?.currency || ""}&search=${payload?.search || ""}`,
        {
          headers: {
            Authorization: `Token ${ACCESS_TOKEN}`,
          },
        }
      )
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };
  // getAllTransactions = (payload = {}) => {
  //   const url = `${API_URL}api/v1/users/transactions?trxType=${
  //     payload?.trxType || ""
  //   }&operation_type=${payload?.operationType || ""}&history_from=${
  //     payload?.historyFrom || ""
  //   }&currency=${payload?.currency || ""}`;
  //   return axios
  //     .get(url, {
  //       headers: {
  //         Authorization: `Token ${ACCESS_TOKEN}`,
  //       },
  //     })
  //     .then(function (response) {
  //       if (response.status === 200 || response.status === 201) {
  //         return response.data;
  //       } else {
  //         return {
  //           data: {
  //             results: [],
  //           },
  //         };
  //       }
  //     })
  //     .catch(function (error) {
  //       return error.response?.data;
  //     });
  // };
}
